<div class="side-nav scrollbar-wrapper" sideNav>

  <app-test-period-banner></app-test-period-banner>


    <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu"
        [ngClass]="{
            'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark,
            'menu-license-expired' : licenseService.expired
        }">

      <li [ngClass]="item.submenu.length > 0 ? 'ant-menu-submenu ant-menu-submenu-inline' : 'ant-menu-item'"
          *ngFor="let item of menuItems"

          [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
          [routerLinkActiveOptions]="{exact: false}">
        <a href="javascript:void(0);" class="ant-menu-submenu-title" [ngClass]="{ 'ignore-license' : item.allowIfExpired }" *ngIf="item.submenu.length > 0">
          <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [nzTheme]="item.iconTheme"></i>
          <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
          <span>{{item.title}}</span>
          <i class="ant-menu-submenu-arrow"></i>
        </a>
        <a [routerLink]="item.path" *ngIf="!item.submenu.length > 0" [ngClass]="{ 'ignore-license' : item.allowIfExpired }">
          <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [nzTheme]="item.iconTheme"></i>
          <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
          <span>{{item.title}}</span>
        </a>

        <!-- Second Level -->
        <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
          <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
              *ngFor="let subItem of item.submenu"
              [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
              [routerLinkActiveOptions]="{exact: false}">
            <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" [ngClass]="{ 'ignore-license' : item.allowIfExpired }" *ngIf="subItem.submenu.length > 0">
              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [nzTheme]="subItem.iconTheme"></i>
              <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                 [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
              <span>{{subItem.title}}</span>
              <i class="ant-menu-submenu-arrow"></i>
            </a>
            <a [routerLink]="subItem.path" class="p-l-30" [ngClass]="{ 'ignore-license' : item.allowIfExpired }" *ngIf="!subItem.submenu.length > 0">
              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon" [nzTheme]="subItem.iconTheme"></i>
              <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                 [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
              <span>{{subItem.title}}</span>
            </a>

            <!-- Third Level -->
            <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
              <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                  *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"
                  [routerLinkActiveOptions]="{exact: false}">
                <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" [ngClass]="{ 'ignore-license' : item.allowIfExpired }" *ngIf="subItem.submenu.length > 0">
                  <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                     [nzTheme]="subItem.iconTheme"></i>
                  <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                     [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                  <span>{{subItem.title}}</span>
                  <i class="ant-menu-submenu-arrow"></i>
                </a>
                <a [routerLink]="subItem.path" class="p-l-50" [ngClass]="{ 'ignore-license' : item.allowIfExpired }" *ngIf="!subItem.submenu.length > 0">
                  <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                     [nzTheme]="subItem.iconTheme"></i>
                  <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                     [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                  <span>{{subItem.title}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

  </div>
